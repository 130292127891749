// debugger
/** @type {HTMLButtonElement} */
const TopLeaveOrder=document.querySelector('#TopLeaveOrder')
/** @type {HTMLInputElement} */
const ContactName = document.querySelector('#ContactName')
/** @type {HTMLInputElement} */
const ConsentTick = document.querySelector('#ConsentTick')
/** @type {HTMLInputElement} */
const ContactPhone = document.querySelector('#ContactPhone')
/** @type {HTMLInputElement} */
const TopContactName = document.querySelector('#TopContactName')
/** @type {HTMLInputElement} */
const TopContactPhone = document.querySelector('#TopContactPhone')
TopLeaveOrder.onclick=(e)=>{
  // scroll go to the bottom form ?
  // add the data from top form
  // into
  ContactName.value=TopContactName.value
  ContactPhone.value=TopContactPhone.value
  ConsentTick.focus()

  e.preventDefault()
  return false
}